import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const notfound = () => {
  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <Helmet
          title={`Page Not Found - Aoga Amata Newtown`}
          meta={[
            {
              name: "description",
              content: "About us - Aoga Amata Newtown",
            },
            { name: "keywords", content: "daycare, wellington, newtown" },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="wrapper">
          <h2>Oops, we can't find your page</h2>
          <p>
            But you can go back to the homepage by clicking on{" "}
            <Link to="/">this link</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default notfound;
